<template>
  <a-card :body-style="{ padding: '8px 16px' }" :bordered="false" :hoverable="true">
    <a-tooltip placement="topLeft" :title="project.name">
      <div style="width: 200px; font-size: 16px; font-weight: bold; user-select: none; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ project.name }}</div>
    </a-tooltip>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-row>
          <a-col v-for="sourceType in sourceTypes" :key="sourceType" :span="12">
            <a-card :body-style="{ padding: '8px 0' }" :bordered="false" :hoverable="true">
              <source-count :project="project" size="small" :source-type="sourceType"></source-count>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <severity-pie-chart :chart-id="project.id" :date-range="dateRange" :project-id="project.id"></severity-pie-chart>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import moment from 'moment'
import SourceCount from '@/components/SourceCount'
import SeverityPieChart from './SeverityPieChart'

export default {
  name: 'ProjectCard',
  components: {
    SeverityPieChart,
    SourceCount
  },
  props: {
    project: { type: Object, required: true }
  },
  data () {
    return {
      sourceTypes: ['host', 'middleware', 'database', 'web'],
      dateRange: [moment(), moment()]
    }
  }
}
</script>
