<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :sm="24" :lg="12" :xl="8" :xxl="6">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入项目名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-row :gutter="16">
      <a-col v-for="project in projects" :key="project.id" :sm="24" :lg="12" :xl="8" :xxl="6" style="margin-bottom: 16px;">
        <project-card :project="project" style="height: 200px;"></project-card>
      </a-col>
      <a-col v-if="hasMore" :sm="24" :lg="12" :xl="8" :xxl="6" style="margin-bottom: 16px;">
        <a-card :body-style="{ padding: '8px 16px' }" :bordered="false" @click="loadMore" >
          <a style="height: 180px; display: -webkit-flex; justify-content: center; align-items: center; user-select: none;">加载更多...</a>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getProjectList } from '@/api/project'
import ProjectCard from './modules/ProjectCard'

export default {
  name: 'ProjectStatistic',
  components: {
    ProjectCard
  },
  data () {
    return {
      form: { page: 1, page_size: 10 },
      projects: [],
      total: 0
    }
  },
  computed: {
    hasMore () {
      return this.total > this.form.page * this.form.page_size
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getProjectList(this.form).then(res => {
        const data = res.data
        this.projects.push(...data.data)
        this.total = data.total
      })
    },
    search () {
      this.form.page = 1
      this.projects = []
      this.fetch()
    },
    loadMore () {
      if (this.hasMore) {
        this.form.page++
        this.fetch()
      }
    }
  }
}
</script>
