<template>
  <a-statistic style="text-align: center; user-select: none;">
    <div slot="title" :style="{ fontSize: `${fontSize.title}px` }">{{ $t(`source_type.${sourceType}`) }}</div>
    <router-link :to="{ name: routerName, params: { project: { key: this.project.id, label: this.project.name } } }" slot="formatter">
      <span :style="{ fontSize: `${fontSize.big}px`, color: '#41c25e' }">{{ count.monitored }}</span>
      <span :style="{ fontSize: `${fontSize.small}px`, color: '#8494a5' }">/</span>
      <span :style="{ fontSize: `${fontSize.small}px`, color: '#8494a5' }">{{ count.unmonitored + count.monitored }}</span>
    </router-link>
  </a-statistic>
</template>

<script>
import {
  getSourceCountFunc,
  getSourceListRouterName
} from '@/utils'

export default {
  name: 'SourceCount',
  props: {
    project: { type: Object, required: true },
    size: { type: String, default: 'default' },
    sourceType: { type: String, required: true }
  },
  data () {
    return {
      count: { monitored: 0, unmonitored: 0 }
    }
  },
  computed: {
    fontSize () {
      if (this.size === 'small') {
        return { title: 8, big: 20, small: 12 }
      } else {
        return { title: 14, big: 36, small: 18 }
      }
    },
    countFunc () {
      return getSourceCountFunc(this.sourceType)
    },
    routerName () {
      return getSourceListRouterName(this.sourceType)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.countFunc({ project_id: this.project.id }).then(res => {
        res.data.data.forEach(item => {
          this.count[item.name] = item.value
        })
      })
    }
  }
}
</script>
