<template>
  <a-statistic style="user-select: none;">
    <div slot="title" style="font-size: 8px; text-align: center;">今日告警</div>
    <div slot="formatter" :id="chartId">
      <a-empty v-show="empty" :image-style="{ height: '64px', margin: '16px 0 16px -10px' }">
        <div slot="description" style="font-size: 12px; color: #bfbfbf;">未发生告警</div>
      </a-empty>
    </div>
  </a-statistic>
</template>

<script>
import { Chart } from '@antv/g2'
import { getAlertEventCount } from '@/api/alert-event'
import { severityColor } from '@/utils'

export default {
  name: 'SeverityPieChart',
  props: {
    chartId: {
      type: String,
      required: true
    },
    dateRange: {
      type: Array
    },
    projectId: {
      type: String
    }
  },
  data () {
    return {
      chart: null,
      empty: false
    }
  },
  mounted () {
    this.chart = new Chart({
      container: this.chartId,
      height: 126,
      autoFit: true
    })
    this.chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.6
    })
    this.chart.tooltip({
      showTitle: false,
      showMarkers: false
    })
    this.chart.scale('name', {
      formatter: value => value ? this.$t(`severity.${value}`) : value
    })
    this.chart
      .interval()
      .adjust('stack')
      .position('value')
      .color('name', v => severityColor(v))
    this.chart.interaction('element-highlight-by-color')
    this.fetch()
  },
  methods: {
    fetch () {
      const params = { count_by: 'severity' }
      if (this.dateRange.length !== 0) {
        params.date_from = this.dateRange[0].format('YYYY-MM-DD')
        params.date_to = this.dateRange[1].format('YYYY-MM-DD')
      }
      if (this.projectId) params.project_id = this.projectId
      getAlertEventCount(params).then(res => {
        const data = res.data.data
        console.log(data)
        if (data.length !== 0) {
          this.chart.changeVisible(true)
          this.empty = false
          this.chart.data(data)
          this.chart.forceFit()
          this.chart.render()
        } else {
          this.chart.changeVisible(false)
          this.empty = true
        }
      })
    }
  },
  watch: {
    dateRange () {
      this.fetch()
    },
    projectId () {
      this.fetch()
    }
  }
}
</script>
